import React from 'react'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../hooks/use-site-metadata'
import createDocumentTitle from '../lib/document-title'

interface SiteMapProps {
  pageContext: {
    paths: string[]
  }
}

export default function SiteMap(props: SiteMapProps) {
  const { title: siteTitle } = useSiteMetadata()
  const pages = props.pageContext.paths.map((path, i) => {
    return (
      <li key={i}>
        <a href={path}>{path}/</a>
      </li>
    )
  })

  return (
    <>
      <Helmet>
        <title>{createDocumentTitle(siteTitle, 'Page List')}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ul>{pages}</ul>
    </>
  )
}
